import CurrentInverterData from '../currentInverterData/currentInverterData';
import ChartsDay from '../charts/chartsDay';

function Home() {
    return (
        <>
            <CurrentInverterData />
            <ChartsDay />
        </>
    );
}

export default Home;
